import DoorStepLeftImg from '../../assets/Door_Step_1.jpg';
import DoorStepRightImg from '../../assets/Door_Step_2.jpg';
import AlphaLeftImg from '../../assets/Alpha_1.jpg';
import AlphaRightImg from '../../assets/Alpha_2.jpg';
import BoldLeftImg from '../../assets/Bold_1.jpg';
import BoldRightImg from '../../assets/Bold_2.jpg';
import MiracleLeftImg from '../../assets/Miracle_1.jpg';
import MiracleRightImg from '../../assets/Miracle_2.jpg';
import WonderLeftImg from '../../assets/wonder_1.jpg';
import WonderRightImg from '../../assets/Wonder_2.jpg';
import JewelLeftImg from '../../assets/Jewel_1.jpg';
import JewelRightImg from '../../assets/Jewel_2.jpg';
import DesignLeftImg from '../../assets/Design_1.jpg';
import DesignRightImg from '../../assets/Design_2.jpg';

import FeatureDoorStep from '../../assets/Feature_Door_step.jpg';
import GalleryDoorStep1 from '../../assets/Gallery_DoorStep1.jpg';
import GalleryDoorStep2 from '../../assets/Gallery_DoorStep2.jpg';
import GalleryDoorStep3 from '../../assets/Gallery_DoorStep3.jpg';

import FeatureAlpha from '../../assets/Feature_Alpha.jpg';
import GalleryAlpha1 from '../../assets/Gallery_Alpha1.jpg';
import GalleryAlpha2 from '../../assets/Gallery_Alpha2.jpg';
import GalleryAlpha3 from '../../assets/Gallery_Alpha3.jpg';

import FeatureBold from '../../assets/Feature_Bold.jpg';
import GalleryBold1 from '../../assets/Gallery_Bold1.jpg';
import GalleryBold2 from '../../assets/Gallery_Bold2.jpg';
import GalleryBold3 from '../../assets/Gallery_Bold3.jpg';

import FeatureMiracle from '../../assets/Feature_Miracle.jpg';
import GalleryMiracle1 from '../../assets/Gallery_Miracle1.jpg';
import GalleryMiracle2 from '../../assets/Gallery_Miracle2.jpg';
import GalleryMiracle3 from '../../assets/Gallery_Miracle3.jpg';

import FeatureWonder from '../../assets/Feature_Wonder.jpg';
import GalleryWonder1 from '../../assets/Gallery_Wonder1.jpg';
import GalleryWonder2 from '../../assets/Gallery_Wonder2.jpg';
import GalleryWonder3 from '../../assets/Gallery_Wonder3.jpg';

import FeatureJewel from '../../assets/Feature_Jewel.jpg';
import GalleryJewel1 from '../../assets/Gallery_Jewel1.jpg';
import GalleryJewel2 from '../../assets/Gallery_Jewel2.jpg';
import GalleryJewel3 from '../../assets/Gallery_Jewel3.jpg';

import FeatureDesign from '../../assets/Feature_Design.jpg';
import GalleryDesign1 from '../../assets/Gallery_Design1.jpg';
import GalleryDesign2 from '../../assets/Gallery_Design2.jpg';
import GalleryDesign3 from '../../assets/Gallery_Design3.jpg';

import vidPlay from '../../assets/DoorStepVideo_BG.jpg';
import alphaVideo from '../../assets/Alpha_Video_BG.jpg';
import boldVideo from '../../assets/Bold_Video_BG.jpg';
import designVideo from '../../assets/Design_Video_BG.jpg';
import jewelVideo from '../../assets/JewelVideo_BG.jpg';
import miracleVideo from '../../assets/MiracleVideo_BG.jpg';
import wonderVideo from '../../assets/WonderVideo_BG.jpg';

import projectDetailsDoorStep from '../../assets/ProjectDetail_Door-Step.jpg';
import projectDetailsAlpha from '../../assets/ProjectDetail_Alpha.jpg';
import projectDetailsBold from '../../assets/ProjectDetail_Bold.jpg';
import projectDetailsMiracle from '../../assets/ProjectDetail_Miracle.jpg';
import projectDetailsWonder from '../../assets/ProjectDetail_Wonder.jpg';
import projectDetailsJewel from '../../assets/ProjectDetail_Jewel.jpg';
import projectDetailsDesign from '../../assets/ProjectDetails_Design.jpg';
import projectDetailsDoorStepMobile from '../../assets/ProjectDetail_Door-StepMobile.jpg';
import projectDetailsAlphaMobile from '../../assets/ProjectDetail_AlphaMobile.jpg';
import projectDetailsBoldMobile from '../../assets/ProjectDetail_BoldMobile.jpg';
import projectDetailsMiracleMobile from '../../assets/ProjectDetail_MiracleMobile.jpg';
import projectDetailsWonderMobile from '../../assets/ProjectDetail_WonderMobile.jpg';
import projectDetailsJewelMobile from '../../assets/ProjectDetail_JewelMobile.jpg';
import projectDetailsDesignMobile from '../../assets/ProjectDetailsMobile.jpg';
import { useSelector } from 'react-redux';

export const useProjectDetails = () => {
  return useSelector((state) => {
    const { SectionData } = state.projects;
    let projectDetails = '';


    if (SectionData && SectionData?.Projects) {
      projectDetails = SectionData.Projects.map(
        (project) => ({
          id: project?.Title?.toLowerCase().replace(/\s+/g, '-'),
          title: project?.Title?.toUpperCase(),
          shortLocation: project?.Location,
          fullLocation: project?.Location,
          shortDate: project?.Duration,
          fullDate: project?.Duration,
          img: project?.BackgroundImage?.url,
          alt: project?.BackgroundImage?.altTag,
          imgM: project?.MobileBgImage?.url,
          altM: project?.MobileBgImage?.altTag,
          workArea: project?.WorkArea,
          description: project?.Description,
          leftImage: project?.Image1?.url,
          leftAlt: project?.Image1.altTag,
          rightImage: project?.Image2?.url,
          rightAlt: project?.Image2?.altTag,
          thumbImage: project?.FeaturedImage?.url,
          thumbAlt: project?.FeaturedImage?.altTag,
          gallery: project?.Gallery,
          videos: project?.videos?.map(video => ({
            youtubeUrl: video?.YoutubeUrl,
            videoCover: video?.Thumbnail,
            altTag: video?.altTag
          })) || []
        })
      );
    }
    return projectDetails;
  });
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';


const useInjectGlobalScripts = (data) => {

    if (!data) return;

    let { beforeHeadClose, afterBodyOpen, beforeBodyClose } = data;




    useEffect(() => {
        const injectScripts = () => {
            // Inject the script before head close (as the last element in the <head>)
            if (beforeHeadClose) {
                const headCloseScripts = document.querySelector('head');
                if (headCloseScripts) {
                    const div = document.createElement('div');
                    div.innerHTML = beforeHeadClose;
                    while (div.firstChild) {
                        headCloseScripts.appendChild(div.firstChild);
                    }
                }
            }

            // Inject the script after body open (at the beginning of the <body>)
            if (afterBodyOpen) {
                const bodyOpenScripts = document.querySelector('body');
                if (bodyOpenScripts) {
                    const div = document.createElement('div');
                    div.innerHTML = afterBodyOpen;
                    while (div.firstChild) {
                        bodyOpenScripts.insertBefore(div.firstChild, bodyOpenScripts.firstChild);
                    }
                }
            }

            // Inject the script before body close (as the last element in <body>)
            if (beforeBodyClose) {
                const bodyCloseScripts = document.querySelector('body');
                if (bodyCloseScripts) {
                    const div = document.createElement('div');
                    div.innerHTML = beforeBodyClose;
                    while (div.firstChild) {
                        bodyCloseScripts.appendChild(div.firstChild);
                    }
                }
            }
        };

        // Delay the script injection to ensure Helmet has completed its work
        const delay = setTimeout(injectScripts, 2000);

        // Clean up timeout if the component unmounts
        return () => clearTimeout(delay);
    }, [beforeHeadClose, afterBodyOpen, beforeBodyClose]); // Re-run if script data changes
};

export default useInjectGlobalScripts;


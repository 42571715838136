import React from 'react';
import { useSelector } from 'react-redux';
import useInjectGlobalScripts from '../../hooks/useInjectGlobalScripts';

const ScriptInjector = () => {
    const { SectionData } = useSelector((state) => state.siteInfo);

    useInjectGlobalScripts(SectionData || {});





    return null;
};

export default ScriptInjector;
